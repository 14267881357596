import * as React from 'react';
import Layout from '../layout/Layout';
// @ts-ignore
import deadLink from '../images/dead-link.png';

const NotFoundPage = (): JSX.Element => (
  <Layout>
    <div className="container">
      <h2>Page not found</h2>
      <p>You may have found a dead link.</p>
      <p>
        <img src={deadLink} alt="Dead Link" />
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
